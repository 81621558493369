<template>
  <div
    class="menu_wrapper_container box-shadow trading-container"
    v-loading="loading"
  >
    <el-card>
      <div class="trading-client">
        <el-form
          :model="formData"
          :rules="formRules"
          ref="refForm"
          label-position="top"
          :status-icon="true"
        >
          <div>
            <el-row>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>Config:</h3>
              </el-col>
            </el-row>
          </div>

          <div>

            <el-row class="super-admin-setting">
              <el-col  :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <p> Day</p>
              </el-col>
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <p>MCX open time</p>
              </el-col>
              <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                <p>MCX close time</p>
              </el-col>
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <p>NSE open time</p>
              </el-col>
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <p>NSE close time</p>
              </el-col>
              <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                <p>FOREX open time</p>
              </el-col>
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <p>FOREX close time</p>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="trading-client" v-for="(d, index) in formData.market_timings" :key="index" >
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <p class="super-admin-setting"> {{ d.day_display }}</p>
              </el-col>

              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <vue-timepicker  v-model="d.mcx_open_time" ></vue-timepicker>
               
              </el-col>
              <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                <vue-timepicker  v-model="d.mcx_close_time" ></vue-timepicker>

              
              </el-col>
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <vue-timepicker  v-model="d.nse_open_time" ></vue-timepicker>
               
              </el-col>
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <vue-timepicker  v-model="d.nse_close_time" ></vue-timepicker>

              </el-col>
              <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                <vue-timepicker  v-model="d.forex_open_time" ></vue-timepicker>

              </el-col>
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <vue-timepicker  v-model="d.forex_close_time" ></vue-timepicker>

              </el-col>
            </el-row>
            <el-row :gutter="20" class="trading-client">
              <!-- <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_start_trading_at"
                  label="MCX Start Trading At:"
                  prop="mcx_start_trading_at"
                  :label-width="formLabelWidth"
                >
                  <TimePicker
                    :selectedTime.sync="formData.mcx_start_trading_at"
                    @timeUpdated="mcxStartTradingAt"
                  />

                  <input v-model="formData.id" autocomplete="off" hidden />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_stop_trading_at"
                  label="MCX Stop Trading At:"
                  prop="mcx_stop_trading_at"
                  :label-width="formLabelWidth"
                >
                  <TimePicker
                    :selectedTime.sync="formData.mcx_stop_trading_at"
                    @timeUpdated="mcxStopTradingAt"
                  />
                </el-form-item>
              </el-col>
              
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_futures_start_trading_at"
                  label="Equity Futures Start Trading At"
                  prop="equity_futures_start_trading_at"
                  :label-width="formLabelWidth"
                >
                  <TimePicker
                    :selectedTime.sync="formData.equity_futures_start_trading_at"
                    @timeUpdated="equityFuturesStartTradingAt"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_futures_stop_trading_at"
                  label="Equity Futures Stop Trading At:"
                  prop="equity_futures_stop_trading_at"
                  :label-width="formLabelWidth"
                >
                  <TimePicker
                    :selectedTime.sync="formData.equity_futures_stop_trading_at"
                    @timeUpdated="equityFuturesStopTradingAt"
                  />
                </el-form-item>
              </el-col> -->
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="super-admin-setting">
                <span>Dollar Rate : </span>
                <span>₹  {{ formData.dollar_rate }}</span>
                
                <!-- <el-form-item
                  :error="formErrors.dollar_rate"
                  label="Dollar Rate"
                  prop="dollar_rate"
                  :label-width="formLabelWidth"
                >
                <el-input v-model="formData.dollar_rate">
                  <template #prepend>₹</template>
                </el-input>
                </el-form-item> -->
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.allow_to_rollover"
                  label=""
                  prop="allow_to_rollover"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_to_rollover"
                    >Allow to rollover</el-checkbox
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="right">
              <el-button type="info" @click="sendChildFormData()">Save</el-button>
              <!-- {{ userInfo.id }} -->
            </el-col>
          </el-row>
        </el-form>
      </div>
   
    </el-card>
  </div>
</template>




<script>
import { fetch, store } from "@/api/settings";
//import TimePicker from "@/components/DatePicker/TimePicker.vue";
import VueTimepicker from 'vue2-timepicker'
export default {
  name: "AddEdit",
  components: {
    VueTimepicker
  },
  props: {
    // dialogVisible: {
    //   type: Boolean,
    //   default: false,
    // },
    // dialogType: {
    //   type: String,
    //   default: "",
    // },
    // formData: {
    //   type: Object,
    //   default: function () {
    //     return {};
    //   },
    // },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formData: {},
      formRules: {},
      formLabelWidth: "120px",
    };
  },
  computed: {},
  created() {
    this.fetchData();
  },
  methods: {
    mcxTradingAt(value, key, index) {
      console.log("SDSD", value, key, index)
      //this.formData.market_timings[index][key] = value;
      console.log("SDSD g", this.formData.market_timings)
    },
    nseTradingAt(value,key, index) {
      this.formData.market_timings[index][key] = value;
    },
    mcxStartTradingAt(value) {
      this.formData.mcx_start_trading_at = value;
    },
    mcxStopTradingAt(value) {
      this.formData.mcx_stop_trading_at = value;
    },
    optStartTradingAt(value) {
      this.formData.opt_start_trading_at = value;
    },
    optStopTradingAt(value) {
      this.formData.opt_stop_trading_at = value;
    },
    equityFuturesStartTradingAt(value) {
      this.formData.equity_futures_start_trading_at = value;
    },
    equityFuturesStopTradingAt(value) {
      this.formData.equity_futures_stop_trading_at = value;
    },
    allowToRollover(value) {
      this.formData.allow_to_rollover = value;
    },
    ///////////////////
    //// EDIT
    ///////////////////
    fetchData() {
      fetch().then((response) => {
        console.log(response);
        this.formData = response.data.data;
        // console.log(this.formData);
        // this.loading = false;
      });
    },
    ///////////////////
    //// UPDATE
    ///////////////////
    sendChildFormData() {
      this.loading = true;
      // if (
      //   this.formData.transaction_password != this.userInfo.transaction_password
      // ) {
      //   this.flashError("Please Enter Correct Transaction Password");
      //   this.formData.transaction_password = null;
      // } else {
      //   this.formData.transaction_password;
      // }
      //   console.log(this.formData)
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          //   this.$emit("getChildFormData", this.formData);

          store(this.formData)
            .then((response) => {
              if (response.data.success === true) {
                this.flashSuccess(response.data.message);
                this.loading = false;
                this.$router.push("/super-admin/settings");
              } else {
                this.formErrors = this.validationErrors(response.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });

          console.log(this.formData);
          this.loading = false;
        }
      });
    },
  },
};
</script>

